import useAuthController from '../hooks/controllers/Auth';

const AuthView = () => {
  const controller = useAuthController();

  return (
    <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-24 w-auto"
          src="/icon.png"
          alt="Workflow"
        />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">chat & talk backoffice</h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={controller.handleSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                E-Mail-Adresse
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  className={'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm \
placeholder-gray-400 focus:outline-none focus:ring-secondary-light focus:border-secondary-light sm:text-sm'}
                  onChange={(e) => controller.setEmail(e.target.value)}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                Passwort
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className={'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm \
placeholder-gray-400 focus:outline-none focus:ring-secondary-light focus:border-secondary-light sm:text-sm'}
                  onChange={(e) => controller.setPassword(e.target.value)}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className={'w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm \
text-sm font-medium text-white bg-secondary hover:bg-secondary-light focus:outline-none focus:ring-2 \
focus:ring-offset-2'}
              >
                Einloggen
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AuthView;
