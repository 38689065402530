/* eslint-disable max-len */

import { UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';

export type UseQueryTypeHelper<ApiFunc extends (...args: any) => any> = UseQueryOptions<Awaited<ReturnType<ApiFunc>>, Error, Awaited<ReturnType<ApiFunc>>, any>;
export type UseMutationTypeHelper<ApiFunc extends (...args: any) => any, Params extends Record<string, any> | void> = UseMutationOptions<Awaited<ReturnType<ApiFunc>>, Error, Params>;

export enum PopoverModalType {
  Error = 0,
  Warning = 1,
  Info = 2,
}

export interface PopoverModalButton {
  solid?: boolean;
  label: string;
  onClick: () => void;
}
export interface PopoverModal {
  message: string;
  type: PopoverModalType;
  buttons?: PopoverModalButton[];
  afterClose?: () => void;
}
