import ReportsTable from '../../components/ReportsTable';
import useDashboardReportsController from '../../hooks/controllers/Dashboard/Reports';

const DashboardReportsView = () => {
  const controller = useDashboardReportsController();
  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 pb-4">
        Meldungen
      </h1>
      <ReportsTable
        reports={controller.reports || []}
      />
    </>
  );
};

export default DashboardReportsView;
