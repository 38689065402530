import React from 'react';
import UsersTableRow, { UserRow } from './UsersTableRow';

interface UsersTableProps {
  users: UserRow[]
}
const UsersTable: React.FC<UsersTableProps> = (props) => {
  const { users } = props;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {users.map((user) => <UsersTableRow user={user} />)}
      </ul>
    </div>
  );
};

export default UsersTable;
