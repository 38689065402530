import { useNavigate } from 'react-router-dom';
import { PopoverModalType } from '../../../types/global';
import { useGetAllReports, useGetReport, useResolveReport } from '../../query/report';
import {
  useGetSelfie, useGetUser,
} from '../../query/user';
import useStore from '../../useStore';

const useDashboardUserDetailsController = (reportId: string) => {
  const report = useGetReport(reportId);
  const reportedUser = useGetUser(
    report.data?.reportedId || '',
    {
      enabled: !!report.data?.reportedId,
    },
  );
  const reportedUserSelfie = useGetSelfie(
    report.data?.reportedId || '',
    {
      enabled: !!report.data?.reportedId,
    },
  );
  const allReports = useGetAllReports();
  const { mutate: resolveReport } = useResolveReport({
    onSuccess: () => {
      allReports.refetch();
      report.refetch();
      setModal({
        type: PopoverModalType.Info,
        message: 'Die Meldung wurde erfolgreich bearbeitet.',
        afterClose: () => {
          navigate('/dashboard/reports');
        },
      });
    },
  });

  const navigate = useNavigate();
  const setModal = useStore((state) => state.setModal);

  if (!reportId) {
    navigate('/dashboard/reports');
  }

  const handlePressBan = () => {
    if (!report.data) return;
    setModal({
      type: PopoverModalType.Warning,
      message: 'Möchten Sie diesen Benutzer wirklich sperren? Er wird sich anschließend nicht mehr einloggen können. \
Der Nutzer kann jederzeit wieder entsperrt werden.',
      buttons: [
        {
          label: 'Ja, sperren',
          onClick: () => {
            resolveReport({ reportId, ban: true });
            setModal(null);
            // TODO: loading screen
          },
          solid: true,
        },
        {
          label: 'Abbrechen',
          onClick: () => {
            setModal(null);
          },
        },
      ],
    });
  };

  const handlePressDismiss = () => {
    if (!report.data) return;
    setModal({
      type: PopoverModalType.Warning,
      message: 'Möchten Sie diese Meldung wirklich verwerfen? Es wird keine weitere Aktion vorgenommen.',
      buttons: [
        {
          label: 'Ja, verwerfen',
          onClick: () => {
            resolveReport({ reportId, ban: false });
            setModal(null);
            // TODO: loading screen
          },
          solid: true,
        },
        {
          label: 'Abbrechen',
          onClick: () => {
            setModal(null);
          },
        },
      ],
    });
  };

  return {
    report: report.data,
    reportedUser: reportedUser.data,
    reportedUserSelfie: reportedUserSelfie.data,
    handlePressBan,
    handlePressDismiss,
  };
};

export default useDashboardUserDetailsController;
