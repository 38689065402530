import { Fragment, useMemo } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon, XIcon } from '@heroicons/react/outline';
import useStore from '../hooks/useStore';
import { PopoverModalType } from '../types/global';

const PopoverModal = () => {
  const modal = useStore((state) => state.modal);
  const setModal = useStore((state) => state.setModal);

  const title = useMemo(() => {
    if (!modal) return null;
    if (modal.type === PopoverModalType.Error) return 'Fehler';
    if (modal.type === PopoverModalType.Warning) return 'Warnung';
    if (modal.type === PopoverModalType.Info) return 'Hinweis';
    return null;
  }, [modal]);

  const close = () => {
    setModal(null);
    if (modal?.afterClose) modal.afterClose();
  };

  return (
    <Transition.Root show={modal !== null} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden \
shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6"
              >
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none \
focus:ring-2 focus:ring-offset-2 focus:ring-secondary-light"
                    onClick={close}
                  >
                    <span className="sr-only">Schließen</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                { modal && (
                <>
                  <div className="sm:flex sm:items-start">
                    <div className={`mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full \
${modal?.type !== PopoverModalType.Info ? 'bg-red-100' : 'bg-blue-100'} sm:mx-0 sm:h-10 sm:w-10`}
                    >
                      <ExclamationIcon
                        className={
                      `h-6 w-6 ${modal?.type !== PopoverModalType.Info ? 'text-red-600' : 'text-blue-600'}`
                    }
                        aria-hidden="true"
                      />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          {modal?.message}
                        </p>
                      </div>
                    </div>
                  </div>
                  {modal?.buttons && (
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-3">
                    {modal.buttons.map((button) => (
                      <button
                        key={button.label}
                        type="button"
                        className={button.solid
                          ? `w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 \
py-2 ${modal?.type !== PopoverModalType.Info ? 'bg-red-600' : 'bg-blue-600'} text-base font-medium text-white \
${modal?.type !== PopoverModalType.Info ? 'hover:bg-red-700' : 'hover:bg-blue-700'} focus:outline-none \
focus:ring-2 focus:ring-offset-2 \
${modal?.type !== PopoverModalType.Info ? 'focus:ring-red-500' : 'focus:ring-blue-500'} sm:ml-3 sm:w-auto sm:text-sm`
                          : 'w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm \
px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 \
focus:ring-offset-2 focus:ring-secondary-light sm:mt-0 sm:w-auto sm:text-sm'}
                        onClick={button.onClick}
                      >
                        {button.label}
                      </button>
                    ))}
                  </div>
                  )}
                </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PopoverModal;
