import {
  CheckCircleIcon, InformationCircleIcon, XCircleIcon, ChevronRightIcon, KeyIcon,
} from '@heroicons/react/solid';
import CommonTypes from 'common-types';
import dayjs from 'dayjs';
import { useGetSelfie } from '../hooks/query/user';

export interface UserRow {
  id: string,
  createdOn: Date,
  lastLogin: Date,
  isBanned: boolean,
  isSuperuser: boolean,
  providerType: CommonTypes.Generic.ProviderType,
  providerId: string,
}
interface UsersTableRowProps {
  user: UserRow,
}

const UsersTableRow: React.FC<UsersTableRowProps> = (props) => {
  const { user } = props;
  const selfie = useGetSelfie(user.id, {
    staleTime: 60 * 1000,
  });
  let stateText = (
    <>
      <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" aria-hidden="true" />
      <p>
        {'Aktiv und zuletzt eingeloggt am '}
        <time dateTime={dayjs(user.lastLogin).format('YYYY-MM-DD')}>
          {dayjs(user.lastLogin).format('D. MMMM YYYY')}
        </time>
      </p>
    </>
  );
  if (user.isBanned) {
    stateText = (
      <>
        <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" aria-hidden="true" />
        Nutzer ist gesperrt
      </>
    );
  }
  if (user.isSuperuser) {
    stateText = (
      <>
        <InformationCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-blue-400" aria-hidden="true" />
        Administrator
      </>
    );
  }

  let subtext = 'Unbekannter Login-Provider';
  if (user.providerType === 'google') {
    subtext = 'Login mit Google';
  } else if (user.providerType === 'apple') {
    subtext = 'Login mit Apple';
  } else if (user.providerType === 'local') {
    subtext = 'Login mit lokaler Anmeldung (E-Mail)';
  } else if (user.providerType === 'dummy') {
    subtext = 'Dummy-Account';
  }

  return (
    <li key={user.id}>
      <a href={`/dashboard/users/${user.id}`} className="block hover:bg-gray-50">
        <div className="flex items-center px-4 py-4 sm:px-6">
          <div className="min-w-0 flex-1 flex items-center">
            <div className="flex-shrink-0">
              <img className="h-12 w-12 rounded-lg" src={selfie.data || ''} alt="" />
            </div>
            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
              <div>
                <p className="text-sm font-medium text-indigo-600 truncate">{user.id}</p>
                <p className="mt-2 flex items-center text-sm text-gray-500">
                  <KeyIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span className="truncate">{subtext}</span>
                </p>
              </div>
              <div className="hidden md:block">
                <div>
                  <p className="text-sm text-gray-900">
                    {'Erstellt am '}
                    <time dateTime={dayjs(user.createdOn).format('YYYY-MM-DD')}>
                      {dayjs(user.createdOn).format('D. MMMM YYYY')}
                    </time>
                  </p>
                  <p className="mt-2 flex items-center text-sm text-gray-500">
                    {stateText}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </div>
        </div>
      </a>
    </li>
  );
};

export default UsersTableRow;
