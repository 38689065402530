import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import DashboardShell from './components/DashboardShell';
import Wrapper from './components/Wrapper';
import AuthView from './views/Auth';
import DashboardHomeView from './views/Dashboard/Home';
import DashboardReportsView from './views/Dashboard/Reports';
import DashboardUsersView from './views/Dashboard/Users';
import DashboardUserDetailsView from './views/Dashboard/UserDetails';
import DashboardReportDetailsView from './views/Dashboard/ReportDetails';

const queryClient = new QueryClient();

const App = () => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <Routes>
        <Route path="/" element={<Wrapper />}>
          <Route index element={<AuthView />} />
          <Route path="/dashboard" element={<DashboardShell />}>
            <Route index element={<DashboardHomeView />} />
            <Route path="users" element={<DashboardUsersView />} />
            <Route path="users/:userId" element={<DashboardUserDetailsView />} />
            <Route path="reports" element={<DashboardReportsView />} />
            <Route path="reports/:reportId" element={<DashboardReportDetailsView />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  </QueryClientProvider>
);

export default App;
