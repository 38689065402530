import { Outlet } from 'react-router-dom';
import PopoverModal from './PopoverModal';

const Wrapper = () => (
  <div className="min-h-full bg-gray-50">
    <PopoverModal />
    <Outlet />
  </div>
);

export default Wrapper;
