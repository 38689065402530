import { useGetAllUsers } from '../../query/user';

const useDashboardUsersController = () => {
  const allUsers = useGetAllUsers();

  return {
    users: allUsers.data,
  };
};

export default useDashboardUsersController;
