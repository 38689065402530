import dayjs from 'dayjs';

interface UserSelfieCardProps {
  lastSelfieUpdate: Date | null,
  src: string,
}
const UserSelfieCard: React.FC<UserSelfieCardProps> = (props) => {
  const { lastSelfieUpdate, src } = props;
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="pt-5 sm:pt-6">
        <h1 className="text-2xl font-bold text-gray-900">Snapshot</h1>
        { lastSelfieUpdate ? (
          <>
            <p className="text-sm font-medium text-gray-500">
              {'Hochgeladen am '}
              <time dateTime={lastSelfieUpdate.toISOString()}>
                {dayjs(lastSelfieUpdate).format('DD.MM.YYYY [um] HH:mm:ss')}
              </time>
            </p>
            <img src={src} alt="" className="w-full mt-4" />
          </>
        ) : (
          <p className="text-sm font-medium text-gray-500 pb-5 sm:pb-6">
            Aktuell kein Snapshot hochgeladen.
          </p>
        )}
      </div>
    </div>
  );
};

export default UserSelfieCard;
