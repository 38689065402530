import CommonTypes from 'common-types';
import { useNavigate } from 'react-router-dom';

interface ReportsTableProps {
  reports: CommonTypes.ApiResponse.Report.GetSingle[]
}
const ReportsTable: React.FC<ReportsTableProps> = (props) => {
  const { reports } = props;
  const sortedReports = [
    ...reports.filter((r) => r.status === 'open'),
    ...reports.filter((r) => r.status !== 'open'),
  ];

  const navigate = useNavigate();

  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
          <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Meldungs-ID
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-red-700">
                    Gemeldete Benutzer-ID
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Melder Benutzer-ID
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {sortedReports.map((report, idx) => {
                  let status = 'Unbekannt';
                  if (report.status === 'open') {
                    const totalReports = reports.filter(
                      (r) => r.reportedId === report.reportedId && r.status === 'open',
                    ).length;
                    status = `Offen (${totalReports} offene Meldungen)`;
                  } else if (report.status === 'dismissed') {
                    status = 'Ignoriert';
                  } else if (report.status === 'banned') {
                    status = 'Benutzer gesperrt';
                  }

                  return (
                    <tr
                      key={report.id}
                      className={`${idx % 2 === 0 ? undefined : 'bg-gray-50'} \
${report.status === 'open' ? 'hover:cursor-pointer' : ''}`}
                      onClick={report.status === 'open' ? () => navigate(`/dashboard/reports/${report.id}`) : undefined}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                        {report.id}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{report.reportedId}</td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{report.reporterId}</td>
                      <td className={`whitespace-nowrap px-3 py-4 text-sm text-gray-500 \
${report.status === 'open' ? 'font-bold' : ''}`}
                      >
                        {status}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportsTable;
