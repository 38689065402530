import { useMutation, useQuery } from '@tanstack/react-query';
import { UseMutationTypeHelper, UseQueryTypeHelper } from '../../types/global';
import Api from '../../services/api';

export enum UserQueryKeys {
  GetAll = 'user_get_all',
  GetSingle = 'user_get_single',
  GetSelfie = 'user_get_selfie',
}

const apiClient = new Api();

export const useGetAllUsers = (
  options?: UseQueryTypeHelper<typeof apiClient.getAllUsers>,
) => useQuery(
  [UserQueryKeys.GetAll],
  () => apiClient.getAllUsers(),
  options,
);

export const useGetUser = (
  userId: string,
  options?: UseQueryTypeHelper<typeof apiClient.getUser>,
) => useQuery(
  [UserQueryKeys.GetSingle, userId],
  () => apiClient.getUser(userId),
  options,
);

export const useGetSelfie = (
  userId: string,
  options?: UseQueryTypeHelper<typeof apiClient.getSelfie>,
) => useQuery(
  [UserQueryKeys.GetSelfie, userId],
  () => apiClient.getSelfie(userId),
  options,
);

export const useDeleteUser = (
  options?: UseMutationTypeHelper<typeof apiClient.deleteUser, { userId: string }>,
) => useMutation(
  ({ userId }) => apiClient.deleteUser(userId),
  options,
);

export const useBanUser = (
  options?: UseMutationTypeHelper<typeof apiClient.banUser, { userId: string }>,
) => useMutation(
  ({ userId }) => apiClient.banUser(userId),
  options,
);

export const useUnbanUser = (
  options?: UseMutationTypeHelper<typeof apiClient.unbanUser, { userId: string }>,
) => useMutation(
  ({ userId }) => apiClient.unbanUser(userId),
  options,
);
