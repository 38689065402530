/* eslint-disable no-console */
import dayjs from 'dayjs';
import md5 from 'crypto-js/md5';
import de from 'dayjs/locale/de';
import utc from 'dayjs/plugin/utc';
import type CommonTypes from 'common-types';
import axios, { Method } from 'axios';
import { Buffer } from 'buffer';
import useStore from '../hooks/useStore';

dayjs.extend(utc);
dayjs.locale(de);

export default class Api {
  private verbose: boolean;

  /**
    * Creates a new API client.
    */
  constructor() {
    this.verbose = process.env.NODE_ENV === 'development';
  }

  static getApiUrl() {
    return process.env.REACT_APP_API_URL;
  }

  /**
   * Function to request or post data from/to the REST API.
   * @param {Method} type Defines the HTTP request type (e.g. POST)
   * @param {string} endpoint The REST endpoint to request/post data from/to
   * @param {object} data Optional data that is sent with the request - for GET, this is put in the query. If
   *                      undefined (default), no data is sent at all.
   * @return {Promise<object>} Resolves to an object including the status code ("status") and the JSON response ("body")
   */
  private async request<ReqType = Record<string, string | number | boolean | any[]>>(
    type: Method,
    endpoint: string,
    data: ReqType | undefined = undefined,
  ): Promise<{ status: number, body?: any }> {
    const { jwtToken } = useStore.getState();

    let fullEndpoint = `${Api.getApiUrl()}${endpoint}`;
    if (this.verbose) console.log('API Request', type, fullEndpoint, data, jwtToken);
    else console.log('API Request', type, endpoint);
    if (type === 'GET' && data !== undefined) {
      const query = Object
        // @ts-ignore
        .keys(data)
        .map((k) => `${k}=${(data as Record<string, any>)[k]}`)
        .join('&');
      fullEndpoint += `?${query}`;
    }

    try {
      const response = await axios(fullEndpoint, {
        method: type,
        headers: {
          'X-Client-Version': 'latest',
          Accept: 'application/json',
          ...(type !== 'GET' && data !== undefined && { 'Content-Type': 'application/json' }),
          ...(jwtToken !== null && { Authorization: `Bearer ${jwtToken}` }),
        },
        data: type !== 'GET' && data !== undefined ? JSON.stringify(data) : undefined,
        validateStatus: () => true,
      });
      const statusCode = response.status;
      if (statusCode === 204) {
        return await Promise.resolve({ status: statusCode });
      }
      const json = response.data;
      if (
        statusCode !== 200
        && json.message.length
      ) {
        console.warn('API Error', type, endpoint, data, statusCode, json);
        return await Promise.reject(new Error(json.message));
      }
      const result = { status: statusCode, body: json };
      if (this.verbose) console.log('API Response', type, endpoint, data, result);
      else console.log('API Response', type, endpoint, statusCode);
      return await Promise.resolve(result);
    } catch (e: any) {
      console.warn('API Error', type, endpoint, data, e.message, e.response);
      return Promise.reject(e);
    }
  }

  /**
   * Authenticates using an e-mail and password
   * @param {string} email The e-mail address to authenticate with
   * @param {string} password The password to authenticate with
   * @return {Promise<CommonTypes.ApiResponse.Auth.Apple>} Returns a promise with the full response
   */
  async authenticateLocally(email: string, password: string): Promise<CommonTypes.ApiResponse.Auth.Apple> {
    try {
      const raw = (await this.request<CommonTypes.ApiRequest.Auth.Local>(
        'POST',
        '/auth/local',
        {
          email,
          password: md5(password).toString(),
        },
      )).body as CommonTypes.ApiResponse.Auth.Apple;

      return await Promise.resolve(raw);
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }

  /**
   * Renews the given JWT token, if possible.
   * @param {string} jwtToken The JWT token to renew
   * @return {Promise<string>} Returns a promise containing the new JWT token
   */
  async renewJwt(jwtToken: string): Promise<string> {
    try {
      const raw = (await this.request<CommonTypes.ApiRequest.Auth.Renew>(
        'POST',
        '/auth/renew',
        {
          token: jwtToken,
        },
      )).body as CommonTypes.ApiResponse.Auth.Renew;

      return await Promise.resolve(raw.token);
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }

  /**
   * Revokes the given JWT token and logs out the user.
   * @param {string} jwtToken The JWT token to revoke
   * @return {Promise<void>} Returns once revoked
   */
  async revokeJwt(jwtToken: string): Promise<void> {
    try {
      await this.request<CommonTypes.ApiRequest.Auth.Revoke>(
        'POST',
        '/auth/revoke',
        {
          token: jwtToken,
        },
      );

      return await Promise.resolve();
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }

  /**
   * Fetches information about all users from the API.
   * @return {Promise<CommonTypes.ApiResponse.User.GetSingleOwn[]>} Resolves to the fetched user data
   */
  async getAllUsers(): Promise<CommonTypes.ApiResponse.User.GetSingleOwn[]> {
    try {
      const raw = (await this.request(
        'GET',
        '/user',
      )).body as CommonTypes.ApiResponse.User.GetSingleOwn[];

      return await Promise.resolve(raw);
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }

  /**
   * Fetches information about the given user from the API.
   * @param {string} userId The ID of the user to fetch
   * @return {Promise<CommonTypes.ApiResponse.User.GetSingleOwn>} Resolves to the fetched user data
   */
  async getUser(userId: string): Promise<CommonTypes.ApiResponse.User.GetSingleOwn> {
    try {
      const raw = (await this.request(
        'GET',
        `/user/${userId}`,
      )).body as CommonTypes.ApiResponse.User.GetSingleOwn;

      return await Promise.resolve(raw);
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }

  /**
   * Gets the selfie for the given user.
   * @param {string} userId The user to get the selfie from.
   * @return {Promise<string>} Resolves to a base64 blob URL with the requested selfie.
   */
  async getSelfie(userId: string): Promise<string> {
    const { jwtToken } = useStore.getState();
    try {
      const pathRes = (await this.request(
        'GET',
        `/user/${userId}/selfie`,
      )).body as CommonTypes.ApiResponse.User.GetSelfie;

      const rawSelfie = (await axios.get(
        `${Api.getApiUrl()}${pathRes.path}?timestamp=${pathRes.timestamp}`,
        {
          responseType: 'arraybuffer',
          headers: {
            'X-Client-Version': 'latest',
            ...(jwtToken !== null && { Authorization: `Bearer ${jwtToken}` }),
          },
        },
      )).data as string;

      return `data:image/png;base64,${Buffer.from(rawSelfie, 'binary').toString('base64')}`;
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('Unbekannter Fehler.')); // TODO: Proper error message
    }
  }

  /**
   * Completely deletes the given user and all associated data from the database.
   * @param {string} userId The id of the user to delete.
   * @return {Promise<void>} Returns once deleted
   */
  async deleteUser(userId: string): Promise<void> {
    try {
      await this.request(
        'DELETE',
        `/user/${userId}`,
      );

      return await Promise.resolve();
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }

  /**
   * Bans the given user from using the app.
   * @param {string} userId The id of the user to ban.
   * @return {Promise<void>} Returns once banned
   */
  async banUser(userId: string): Promise<void> {
    try {
      await this.request(
        'POST',
        `/user/${userId}/ban`,
      );

      return await Promise.resolve();
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }

  /**
   * Unbans the given user, so that he can use the app again.
   * @param {string} userId The id of the user to unban.
   * @return {Promise<void>} Returns once unbanned
   */
  async unbanUser(userId: string): Promise<void> {
    try {
      await this.request(
        'POST',
        `/user/${userId}/unban`,
      );

      return await Promise.resolve();
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }

  /**
   * Gets all reports from the database.
   * @return {Promise<CommonTypes.ApiResponse.Report.GetSingle[]>} Resolves to the fetched reports
   */
  async getAllReports(): Promise<CommonTypes.ApiResponse.Report.GetSingle[]> {
    try {
      const res = (await this.request(
        'GET',
        '/report',
      )).body as CommonTypes.ApiResponse.Report.GetSingle[];

      return await Promise.resolve(res);
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }

  /**
   * Gets information about the given report from the database.
   * @return {Promise<CommonTypes.ApiResponse.Report.GetSingle>} Resolves to the fetched report
   */
  async getReport(reportId: string): Promise<CommonTypes.ApiResponse.Report.GetSingle> {
    try {
      const res = (await this.request(
        'GET',
        `/report/${reportId}`,
      )).body as CommonTypes.ApiResponse.Report.GetSingle;

      return await Promise.resolve(res);
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }

  /**
   * Resolves the given report by either banning the user or simply dismissing it.
   * @return {Promise<void>} Resolves once resolved
   */
  async resolveReport(reportId: string, ban: boolean): Promise<void> {
    try {
      await this.request(
        'POST',
        `/report/${reportId}/${ban ? 'resolve' : 'dismiss'}`,
      );

      return await Promise.resolve();
    } catch (e) {
      if (e instanceof Error) return Promise.reject(e);
      return Promise.reject(new Error('unknown'));
    }
  }
}
