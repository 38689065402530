import DashboardActions from '../../components/DashboardActions';

const DashboardHomeView = () => (
  <>
    <h1 className="text-2xl font-semibold text-gray-900 pb-4">
      Startseite
    </h1>
    <h2 className="text-xl font-semibold text-gray-900 pb-4">
      Externe Links
    </h2>
    <DashboardActions />
  </>
);

export default DashboardHomeView;
