import { useGetAllReports } from '../../query/report';

const useDashboardReportsController = () => {
  const allReports = useGetAllReports();

  return {
    reports: allReports.data,
  };
};

export default useDashboardReportsController;
