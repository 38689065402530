import { useMutation, useQuery } from '@tanstack/react-query';
import { UseMutationTypeHelper, UseQueryTypeHelper } from '../../types/global';
import Api from '../../services/api';

export enum UserQueryKeys {
  GetAll = 'report_get_all',
  GetSingle = 'report_get_single',
}

const apiClient = new Api();

export const useGetAllReports = (
  options?: UseQueryTypeHelper<typeof apiClient.getAllReports>,
) => useQuery(
  [UserQueryKeys.GetAll],
  () => apiClient.getAllReports(),
  options,
);

export const useGetReport = (
  reportId: string,
  options?: UseQueryTypeHelper<typeof apiClient.getReport>,
) => useQuery(
  [UserQueryKeys.GetSingle, reportId],
  () => apiClient.getReport(reportId),
  options,
);

export const useResolveReport = (
  options?: UseMutationTypeHelper<typeof apiClient.resolveReport, { reportId: string, ban: boolean }>,
) => useMutation(
  ({ reportId, ban }) => apiClient.resolveReport(reportId, ban),
  options,
);
