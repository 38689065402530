import create from 'zustand';
import { persist } from 'zustand/middleware';
import { PopoverModal } from '../types/global';

interface AppStore {
  jwtToken: string | null;
  setJwtToken: (jwtToken: string | null) => void;
  modal: PopoverModal | null;
  setModal: (error: PopoverModal | null) => void;
}
const useStore = create<AppStore>()(
  persist(
    (set) => ({
      jwtToken: null,
      setJwtToken: (jwtToken: string | null) => set(() => ({ jwtToken })),
      modal: null,
      setModal: (modal: PopoverModal | null) => set(() => ({ modal })),
    }),
    {
      name: 'app-store',
    },
  ),
);

export default useStore;
