import { useParams } from 'react-router-dom';
import UserDetailsList from '../../components/UserDetailsList';
import UserHeader from '../../components/UserHeader';
import UserSelfieCard from '../../components/UserSelfieCard';
import useDashboardUserDetailsController from '../../hooks/controllers/Dashboard/UserDetails';

const DashboardUserDetailsView = () => {
  const { userId } = useParams();
  const controller = useDashboardUserDetailsController(userId!);

  if (!controller.user) return null; // TODO: Loading screen
  return (
    <>
      <UserHeader
        id={controller.user.id}
        createdOn={new Date(controller.user?.createdOn)}
        onPressBan={controller.handlePressBan}
        onPressDelete={controller.handlePressDelete}
        banned={controller.user.isBanned}
        selfie={controller.selfie || ''}
      />
      <div className="mt-8">
        <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start gap-4">
          <div className="flex-initial text-center w-full lg:w-1/3">
            <UserSelfieCard
              lastSelfieUpdate={controller.user?.lastSelfieUpdate ? new Date(controller.user.lastSelfieUpdate) : null}
              src={controller.selfie || ''}
            />
          </div>
          <div className="flex-1 text-center">
            <UserDetailsList user={controller.user} />
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardUserDetailsView;
