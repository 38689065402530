interface ReportHeaderProps {
  id: string;
  reportedUserId: string;
  reportedSelfieSrc: string;
  onPressBan: () => void;
  onPressDismiss: () => void;
}
const ReportHeader: React.FC<ReportHeaderProps> = (props) => {
  const {
    id, reportedUserId, reportedSelfieSrc, onPressBan, onPressDismiss,
  } = props;

  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-5">
      <div className="flex items-start space-x-5">
        <div className="flex-shrink-0">
          <div className="relative">
            <img
              className="h-16 w-16 rounded-lg"
              src={reportedSelfieSrc}
              alt=""
            />
            <span className="absolute inset-0 shadow-inner rounded-full" aria-hidden="true" />
          </div>
        </div>
        {/*
          Use vertical padding to simulate center alignment when both lines of text are one line,
          but preserve the same layout if the text wraps without making the image jump around.
        */}
        <div className="pt-1.5">
          <h1 className="text-2xl font-bold text-gray-900">
            {'Meldung: '}
            {id}
          </h1>
          <p className="text-sm font-medium text-gray-500">
            {'Gemeldeter Benutzer: '}
            {reportedUserId}
          </p>
        </div>
      </div>
      <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse \
sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3"
      >
        <button
          type="button"
          onClick={onPressDismiss}
          className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm \
font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 \
focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-secondary-light"
        >
          Verwerfen
        </button>
        <button
          type="button"
          onClick={onPressBan}
          className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium \
rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 \
focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-red-500"
        >
          Sperren
        </button>
      </div>
    </div>
  );
};

export default ReportHeader;
