import { useNavigate } from 'react-router-dom';
import { PopoverModalType } from '../../../types/global';
import {
  useBanUser,
  useDeleteUser, useGetAllUsers, useGetSelfie, useGetUser, useUnbanUser,
} from '../../query/user';
import useStore from '../../useStore';

const useDashboardUserDetailsController = (userId: string) => {
  const user = useGetUser(userId);
  const allUsers = useGetAllUsers();
  const selfie = useGetSelfie(userId);
  const { mutate: deleteUser } = useDeleteUser({
    onSuccess: () => {
      allUsers.refetch();
      setModal({
        type: PopoverModalType.Info,
        message: 'Der Benutzer wurde erfolgreich gelöscht.',
        afterClose: () => {
          navigate('/dashboard/users');
        },
      });
    },
  });
  const { mutate: banUser } = useBanUser({
    onSuccess: () => {
      allUsers.refetch();
      user.refetch();
      setModal({
        type: PopoverModalType.Info,
        message: 'Der Benutzer wurde erfolgreich gesperrt.',
      });
    },
  });
  const { mutate: unbanUser } = useUnbanUser({
    onSuccess: () => {
      allUsers.refetch();
      user.refetch();
      setModal({
        type: PopoverModalType.Info,
        message: 'Der Benutzer wurde erfolgreich entsperrt.',
      });
    },
  });

  const navigate = useNavigate();
  const setModal = useStore((state) => state.setModal);

  if (!userId) {
    navigate('/dashboard/users');
  }

  const handlePressBan = () => {
    if (!user.data) return;
    setModal({
      type: PopoverModalType.Warning,
      message: user.data.isBanned ? 'Möchten Sie diesen Benutzer wirklich entsperren? Er wird sich anschließend \
wieder einloggen können. Der Nutzer kann jederzeit wieder gesperrt werden.' : 'Möchten Sie diesen Benutzer wirklich \
sperren? Er wird sich anschließend nicht mehr einloggen können. Der Nutzer kann jederzeit wieder entsperrt werden.',
      buttons: [
        {
          label: user.data.isBanned ? 'Ja, entsperren' : 'Ja, sperren',
          onClick: () => {
            if (user.data.isBanned) unbanUser({ userId });
            else banUser({ userId });
            setModal(null);
            // TODO: loading screen
          },
          solid: true,
        },
        {
          label: 'Abbrechen',
          onClick: () => {
            setModal(null);
          },
        },
      ],
    });
  };

  const handlePressDelete = () => {
    if (!user.data) return;
    setModal({
      type: PopoverModalType.Warning,
      message: 'Möchten Sie diesen Benutzer wirklich löschen? Sämtliche mit diesem Benutzer verbundenen, \
personenbezogenen Daten werden ebenfalls gelöscht.',
      buttons: [
        {
          label: 'Ja, löschen',
          onClick: () => {
            deleteUser({ userId });
            setModal(null);
            // TODO: loading screen
          },
          solid: true,
        },
        {
          label: 'Abbrechen',
          onClick: () => {
            setModal(null);
          },
        },
      ],
    });
  };

  return {
    user: user.data,
    selfie: selfie.data,
    handlePressBan,
    handlePressDelete,
  };
};

export default useDashboardUserDetailsController;
