import CommonTypes from 'common-types';
import dayjs from 'dayjs';

interface UserDetailsListProps {
  user: CommonTypes.ApiResponse.User.GetSingleOwn;
}
const UserDetailsList: React.FC<UserDetailsListProps> = (props) => {
  const { user } = props;

  let providerName = 'Unbekannter Provider';
  if (user.providerType === 'google') {
    providerName = 'Google';
  } else if (user.providerType === 'apple') {
    providerName = 'Apple';
  } else if (user.providerType === 'local') {
    providerName = 'Lokale Authentifizierung / E-Mail';
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Benutzer-Details</h3>
        <p className="mt-1 text-sm text-gray-500">Detaillierte Informationen zum Nutzer</p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Benutzer-ID</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{user.id}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Login-Provider</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {`${providerName} - ${user.providerId}`}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Profil öffentlich?</dt>
            <dd className={`mt-1 text-sm ${user.public ? 'text-green-700' : 'text-red-700'} sm:mt-0 sm:col-span-2`}>
              {user.public ? 'Ja' : 'Nein'}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Letzter Geo-Standort</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user.location ? (
                <>
                  <a
                    href={`https://www.google.de/maps/search/${user.location.lat},${user.location.lng}`}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500"
                  >
                    {`${user.location.lat}, ${user.location.lng}`}
                  </a>
                  {` (zuletzt aktualisiert am ${dayjs(user.lastLocationBeacon).format('DD.MM.YYYY HH:mm:ss')})`}
                </>
              ) : (
                'Kein Geo-Standort verfügbar'
              )}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Profilfeld &quot;Eigene Begrüßung&quot;</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user.hello}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Profilfeld &quot;Was steht heute noch an?&quot;</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user.plans}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Profilfeld &quot;Was machst du sonst so?&quot;</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user.job}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Profilfeld &quot;Was interessiert dich am meisten?&quot;
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user.interests}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Nutzer gesperrt?</dt>
            <dd className={`mt-1 text-sm ${!user.isBanned ? 'text-green-700' : 'text-red-700'} sm:mt-0 sm:col-span-2`}>
              {user.isBanned ? 'Ja' : 'Nein'}
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Nutzer ist Administrator?</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {user.isSuperuser ? 'Ja' : 'Nein'}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Erstellt am</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <time dateTime={dayjs(user.createdOn).format('YYYY-MM-DD HH:mm:ss')}>
                {dayjs(user.createdOn).format('DD.MM.YYYY HH:mm:ss')}
              </time>
            </dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Zuletzt eingeloggt am</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <time dateTime={dayjs(user.lastLogin).format('YYYY-MM-DD HH:mm:ss')}>
                {dayjs(user.lastLogin).format('DD.MM.YYYY HH:mm:ss')}
              </time>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
};

export default UserDetailsList;
