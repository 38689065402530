import UsersTable from '../../components/UsersTable';
import useDashboardUsersController from '../../hooks/controllers/Dashboard/Users';

const DashboardUsersView = () => {
  const controller = useDashboardUsersController();

  return (
    <>
      <h1 className="text-2xl font-semibold text-gray-900 pb-4">
        Benutzer
      </h1>
      <UsersTable
        users={(controller.users || []).map((user) => ({
          id: user.id,
          selfieUrl: '',
          createdOn: new Date(user.createdOn),
          lastLogin: new Date(user.lastLogin),
          isBanned: user.isBanned,
          isSuperuser: user.isSuperuser,
          providerId: user.providerId,
          providerType: user.providerType,
        }))}
      />
    </>
  );
};

export default DashboardUsersView;
