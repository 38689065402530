import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useStore from '../useStore';
import { useAuthLocally, useRenewJwt } from '../query/auth';
import { PopoverModalType } from '../../types/global';

const useAuthController = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const setJwtToken = useStore((state) => state.setJwtToken);
  const setModal = useStore((state) => state.setModal);

  const { mutate: authLocally } = useAuthLocally({
    onSuccess: (data) => goToDashboard(data.token),
    onError: (error) => {
      setModal({
        message: error.message,
        type: PopoverModalType.Error,
      });
    },
  });

  const { mutate: renewJwt } = useRenewJwt({
    onSuccess: (data) => goToDashboard(data),
    onError: () => setJwtToken(null),
  });

  useEffect(() => {
    const currentJwtToken = useStore.getState().jwtToken;
    if (currentJwtToken !== null) {
      renewJwt({ jwtToken: currentJwtToken });
    }
  }, [renewJwt]);

  const goToDashboard = (newJwtToken: string) => {
    setJwtToken(newJwtToken);
    navigate('/dashboard');
  };

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    authLocally({ email, password });
  }, [email, password, authLocally]);

  return {
    setEmail,
    setPassword,
    handleSubmit,
  };
};

export default useAuthController;
