import { useMutation } from '@tanstack/react-query';
import { UseMutationTypeHelper } from '../../types/global';
import Api from '../../services/api';

export enum AuthQueryKeys {}

const apiClient = new Api();

export const useAuthLocally = (
  options?: UseMutationTypeHelper<typeof apiClient.authenticateLocally, { email: string, password: string }>,
) => useMutation(
  async ({ email, password }) => {
    const res = await apiClient.authenticateLocally(email, password);
    if (!res.isSuperuser) throw new Error('not_superuser');
    return res;
  },
  options,
);

export const useRenewJwt = (
  options?: UseMutationTypeHelper<typeof apiClient.renewJwt, { jwtToken: string }>,
) => useMutation(
  ({ jwtToken }) => apiClient.renewJwt(jwtToken),
  options,
);

export const useRevokeJwt = (
  options?: UseMutationTypeHelper<typeof apiClient.revokeJwt, { jwtToken: string }>,
) => useMutation(
  ({ jwtToken }) => apiClient.revokeJwt(jwtToken),
  options,
);
