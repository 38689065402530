import { useParams } from 'react-router-dom';
import UserDetailsList from '../../components/UserDetailsList';
import ReportHeader from '../../components/ReportHeader';
import UserSelfieCard from '../../components/UserSelfieCard';
import useDashboardReportDetailsController from '../../hooks/controllers/Dashboard/ReportDetails';

const DashboardReportDetailsView = () => {
  const { reportId } = useParams();
  const controller = useDashboardReportDetailsController(reportId!);

  if (!controller.report) return null; // TODO: Loading screen
  return (
    <>
      <ReportHeader
        id={controller.report.id}
        reportedUserId={controller.report.reportedId}
        reportedSelfieSrc={controller.reportedUserSelfie || ''}
        onPressBan={controller.handlePressBan}
        onPressDismiss={controller.handlePressDismiss}
      />
      <div className="mt-8">
        <div className="flex flex-col lg:flex-row justify-center items-center lg:items-start gap-4">
          <div className="flex-initial text-center w-full lg:w-1/3">
            <UserSelfieCard
              lastSelfieUpdate={
                controller.reportedUser?.lastSelfieUpdate
                  ? new Date(controller.reportedUser.lastSelfieUpdate)
                  : null
              }
              src={controller.reportedUserSelfie || ''}
            />
          </div>
          { controller.reportedUser && (
            <div className="flex-1 text-center">
              <UserDetailsList user={controller.reportedUser} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default DashboardReportDetailsView;
